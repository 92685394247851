<template>
<div class="kyc">
  <van-nav-bar
    title="实名认证"
    fixed
    left-arrow
    @click-left="$router.go(-1)"
  />
  <div class="info">
    <van-field
      v-model="kyc.name"
      label="姓名"
      placeholder="姓名"
      input-align="right"
      :disabled="verified"
    />
    <van-field
      v-model="kyc.idnum"
      label="身份证号"
      placeholder="身份证号"
      input-align="right"
      :disabled="verified"
    />
    <div v-if="verified" class="verified">
      <van-icon class="icon" name="award-o" />
      <span>已认证</span>
    </div>
    <div v-else>
      <div class="ipt-wrapper phone">
        <!-- <van-field
          v-model="phone"
          label="手机号码"
          placeholder="手机号码"
          input-align="right"
        /> -->
          <input class="ipt" type="number" name="phone" ref="phone" v-model="kyc.phone" oninput="if(value.length>11)value=value.slice(0,11)" placeholder="手机号码" />
      </div>
      <div class="ipt-wrapper sms">
          <input class="ipt" type="number" oninput="if(value.length>4)value=value.slice(0,4)" name="sms" ref="sms" v-model="sms" placeholder="验证码" />
          <span v-if="leftTime<=0" class="color-blue" @click="getCode">获取验证码</span>
          <div v-else class="color-gray">
              <span>{{ leftTime }}秒后可重新发送</span>
          </div>
      </div>
      <van-button class="save-btn" type="primary" block @click="onSave">保存</van-button>
    </div>
    
  </div>
</div>
  
</template>
<script>
import { Toast } from 'vant';
export default {
  name: "Kyc",
  data() {
    return {
      loading: false,
      kyc: {
        name: '',
        idnum: '',
        phone: '',
      },
      verified: false,
      sms: '',
      itvl: {},
      leftTime: 0
    };
  },
  computed:{
    
  },
  components:{
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(){
        this.loading = true;
        this.$http.call(this.$http.api.getKyc).then(res=>{
            console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='0'){
              if(resp.data){
                this.kyc = resp.data;
                if(resp.data.name!='' && resp.data.idnum!='' && resp.data.phone!=''){
                  this.verified = true;
                }
              }
            }else{
                Toast(resp.msg);
                if(resp.code == '99'){
                  this.$router.push({name:'login'});
                }
            } 
            
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        }).then(()=>{
            
        });
    },
    onSave() {
      let data = this.kyc;
      data.code = this.sms;
      console.log(data);
      this.$http.call(this.$http.api.postKyc, {
        data
      }).then(res=>{
            console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='0'){
              Toast('已保存');
            }else{
                Toast(resp.msg);
                if(resp.code == '99'){
                  this.$router.push({name:'login'});
                }
            }
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        }).then(()=>{
            
        });
    },
    getCode(){
      if(this.loading) return;
      if(!/^1\d{10}$/.test(this.kyc.phone)){
          Toast('请输入正确的手机号码');
          return;
      }
      let data = {
          phone: this.kyc.phone
      };
      let _this = this;
      this.loading = true;
      this.$http.call(this.$http.api.sms,{
        params: {scene: 'bind'},
        data
      }).then((res)=>{
          let resp = res.data;
          Toast(resp.msg)
          _this.leftTime = 60;
          _this.itvl = setInterval(()=>{
              _this.leftTime -= 1;
              if(_this.leftTime <= 0){
                _this.leftTime = 0;
                clearInterval(_this.itvl);
              }
          },1000);
      },function(res){
          console.log('something goes wrong...');
          console.log(res);
      }).then(()=>{
          this.loading = false;
      });
  }, 
  },
};
</script>
<style lang="less" scoped>
.kyc {
    padding: 60px 0;
    .info {
      padding: 15px;
      .save-btn {
        margin-top: 15px;
      }
      .ipt-wrapper {
        // margin-bottom: 35px;
        background: #fefefe;
        border: 1px solid #f1f1f1;
        border-radius: 4px;
        padding: 10px;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ipt {
          flex: 1;
          outline: none;
          border: none;
          background: transparent;
        }
      }.phone{
        margin-top: 15px;
      }
      .verified {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #07c160;
        color: #fff;
        width: 100px;
        height: 100px;
        margin: 10px auto;
        border-radius: 50%;
        font-weight: bold;
        opacity: .5;
        .icon {
          font-size: 30px;
          padding: 5px;
        }
      }
    }
}
</style>
